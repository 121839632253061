<template>
	<div class="">
		<div id="">
			<el-image style="height: 400px; width: 100%" :src="require('@/assets/images/notice.jpg')"></el-image>
		</div>
		<div class="page-content" style="margin-top: 40px;">
			<div>
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>协会通告</el-breadcrumb-item>
					<el-breadcrumb-item>{{activeName}}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div style="">
				<div style="display: flex;">
					<div style="width: 75%;" class="noticeLoading">
						<el-tabs v-model="activeName" @tab-click="handleClick" style="margin-top: 3%;width: 95%;">
							<el-tab-pane label="科研要闻" name="科研要闻">
								<div v-for="(item,index) in noticeList" :key="index">
									<div class="num"></div>
									<p style="color: #18305b;font-weight: normal;">
										<!-- <el-link :underline='false' :href="item.fieldD" target="view_window"
											style="color: #18305b;font-weight: normal;font-size: 16px;">{{item.fieldA}}
										</el-link> -->

										<el-link v-if="item.fieldD!=''" :underline='false' :href="item.fieldD"
											target="view_window"
											style="color: #18305b;font-weight: normal;font-size: 16px;">{{item.fieldA}}
										</el-link>
										<el-link v-else :underline='false' href="javascript:void(0)"
											@click="noticeDetails(index)"
											style="color: #18305b;font-weight: normal;font-size: 16px;">{{item.fieldA}}
										</el-link>

									</p>
									<div style="padding-left: 36px;color: #7d7d7d;font-size: 14px;">
										<div class="two">
											{{item.fieldB}}
										</div>
										<div style="margin-top: 20px;">
											来源：{{item.fieldC}} 丨 {{item.createDate}}
										</div>
									</div>
									<el-divider></el-divider>
								</div>
							</el-tab-pane>
							<el-tab-pane label="干货分享" name="干货分享">
								<div v-for="(item,index) in noticeList" :key="index">
									<div class="num"></div>
									<p style="color: #18305b;font-weight: normal;">
										<el-link v-if="item.fieldD!=''" :underline='false' :href="item.fieldD"
											target="view_window"
											style="color: #18305b;font-weight: normal;font-size: 16px;">{{item.fieldA}}
										</el-link>
										<el-link v-else :underline='false' href="javascript:void(0)"
											@click="noticeDetails(index)"
											style="color: #18305b;font-weight: normal;font-size: 16px;">{{item.fieldA}}
										</el-link>
									</p>
									<div style="padding-left: 36px;color: #7d7d7d;font-size: 14px;">
										<div class="two">
											{{item.fieldB}}
										</div>
										<div style="margin-top: 20px;">
											来源：{{item.fieldC}} 丨 {{item.createDate}}
										</div>
									</div>
									<el-divider></el-divider>
								</div>
							</el-tab-pane>

							<el-tab-pane label="协会新闻" name="协会新闻">
								<div v-for="(item,index) in noticeList" :key="index">
									<div class="num"></div>
									<p style="color: #18305b;font-weight: normal;">
										<el-link v-if="item.fieldD!=''" :underline='false' :href="item.fieldD"
											target="view_window"
											style="color: #18305b;font-weight: normal;font-size: 16px;">{{item.fieldA}}
										</el-link>
										<el-link v-else :underline='false' href="javascript:void(0)"
											@click="noticeDetails(index)"
											style="color: #18305b;font-weight: normal;font-size: 16px;">{{item.fieldA}}
										</el-link>
									</p>
									<div style="padding-left: 36px;color: #7d7d7d;font-size: 14px;">
										<div class="two">
											{{item.fieldB}}
										</div>
										<div style="margin-top: 20px;">
											来源：{{item.fieldC}} 丨 {{item.createDate}}
										</div>
									</div>
									<el-divider></el-divider>
								</div>
							</el-tab-pane>
							<el-tab-pane label="资源下载" name="资源下载">
								<el-table :data="noticeList" style="width: 100%;margin-bottom: 40px"
									:show-header="showHeader" empty-text=" ">
									<el-table-column width="655px">
										<template #default="scope">
											<div style="display: flex; align-items: center">
												<img style="width: 20px;" :src="scope.row.fieldC">
												<span style="margin-left: 10px">{{scope.row.fieldA}}</span>
											</div>
										</template>
									</el-table-column>
									<el-table-column prop="fieldB" label="fileSize" width="100px" />
									<el-table-column align="right" width="100px">
										<template #default="scope">
											<el-button size="small" type="danger" style="background-color: #ff5722;"
												@click="handleDow(scope.$index, scope.row)">下载</el-button>
										</template>
									</el-table-column>
								</el-table>
							</el-tab-pane>
						</el-tabs>
						<div v-if="noticeShow" style="margin: auto;">
							<el-empty description="暂无数据"></el-empty>
						</div>
						<div v-if="!noticeShow" class="pagination" style="text-align: center;">
							<el-pagination background layout="prev, pager, next" :total="dataCount"
								@current-change="handleCurrentChange">
							</el-pagination>
						</div>
					</div>
					<div style="width: 25%;">
						<div>
							<p style="font-weight: bold;margin: 20px;">◆ 热门会议</p>
							<div style="display: flex;margin-top: 40px;min-height: 40px;"
								v-for="(item,index) in meetintList" :key="index">
								<div class="num" style="background-color: #e60012;">{{index+1}}</div>
								<div style="color: #18305b;font-weight: normal;width: 90%;">
									<el-link :underline="false" :href="item.fieldB" class="two"
										@click="meetingDetails(index)">
										{{item.fieldC}}
										<el-image :src="require('@/assets/images/hot.gif')" class="index-meeting"
											v-if="item.fieldG=='火'">
										</el-image>
									</el-link>
								</div>
							</div>

							<el-link :underline="false" href="/meeting" style="float: right;margin-top: 40px;">查看更多>
							</el-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import $http from "@/request/http";
	export default {
		props: ["activeNameF"],
		data() {
			return {
				showHeader: false,
				activeName: this.activeNameF,
				noticeShow: false,
				dataCount: 0,
				currentPage: 1,
				noticeList: [],
				meetintList: [],

			};
		},

		methods: {
			meetingDetails(index) {
				
				// window.open("http://" + this.meetintList[index].Site);
			},
			noticeDetails(index) {
				this.$router.push({
					name: "noticeDetails",
					params: this.noticeList[index]
				});
			},
			handleClick() {
				this.currentPage = 1
				this.queryIamsetOtherData()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				this.queryIamsetOtherData()
			},
			queryIamsetOtherData() {
				let _this = this
				let paramMap = {
					page: _this.currentPage,
					pageSize: 10,
					fieldH: _this.activeName == '科研要闻' ? '学术动态' : _this.activeName,
					classification: "notice"
				};
				$http
					.post("queryIamsetOtherData", paramMap, "noticeLoading")
					.then(function(res) {
						if (res.data.length == 0) {
							_this.noticeShow = true
						} else {
							_this.noticeShow = false
						}
						_this.noticeList = res.data;
						_this.dataCount = res.dataCount;
						_this.currentPage = res.pageNo;
					})
					.catch(function() {});
			},
			queryIamsetOtherData2() {
				let _this = this
				let paramMap = {
					page: 1,
					pageSize: 20,
					classification: "meeting"
				};
				$http
					.post("queryIamsetOtherData", paramMap)
					.then(function(res) {
						_this.meetintList = res.data;

					})
					.catch(function() {});
			},

			handleDow(index, row) {
				if(this.$store.state.isLogin=='true'){
					window.location.href = "http://103.120.82.111:8066/downloadLocal?fileName=" + row.fieldD;
				}else{
					alert("请您先登录！")
				}

			}
		},
		created() {
			this.queryIamsetOtherData()
			this.queryIamsetOtherData2()
		},
		mounted() {

		},

	};
</script>

<style lang="scss" scoped>
	.num {
		float: left;
		text-align: center;
		margin-right: 15px;
		color: white;
		width: 22px;
		height: 22px;
		background-color: #ff5722;
		// background-image: linear-gradient(90deg, #1b5faa 0%, #00b4e4 100%);
		background-blend-mode: normal, normal;
	}
</style>