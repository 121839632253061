<template>
	<div class="">
		<notice :activeNameF="activeNameF"></notice>
	</div>
</template>
<script>
	import notice from "@/views/notice/notice";
	export default {
		props: ['searchVal'],
		data() {
			return {
				activeNameF: "科研要闻",
			};
		},
		components: {
			notice,
		},
		created() {},

		methods: {

		},
	};
</script>

<style lang="scss" scoped></style>
